import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Επικοινωνία" />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6Ldy8cEaAAAAAJO07GHrmz1-gxPAdLhHUqfkXG-M" />
      </Helmet>
      <article className="text-gray-800 leading-normal font-serif">
        <h1 className="font-semibold font-sans text-gray-900 mb-6 text-3xl md:text-4xl leading-tight">
          Φόρμα επικοινωνίας
        </h1>
        <form
          method="post"
          action="https://getform.io/f/dea47391-3fd0-4c39-b570-30c15a8d0ced"
          className="max-w-xl mr-auto"
        >
          <input
            type="hidden"
            id="captchaResponse"
            name="g-recaptcha-response"
          />
          <div className="my-2">
            <label className="mb-1" for="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="my-2">
            <label className="mb-1" for="name">
              Ονοματεπώνυμο
            </label>
            <input
              id="name"
              type="text"
              name="name"
              className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="my-2">
            <label className="mb-1" for="message">
              Μήνυμα
            </label>
            <textarea
              id="message"
              name="message"
              rows="5"
              className="shadow appearance-none border rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
            type="submit"
            class="bg-blue-700 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline font-sans"
          >
            Αποστολή
          </button>
        </form>
      </article>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          grecaptcha.ready(function() {
            grecaptcha.execute('6Ldy8cEaAAAAAJO07GHrmz1-gxPAdLhHUqfkXG-M', {action: 'homepage'})
            .then(function(token) {
              document.getElementById('captchaResponse').value = token;
            });
          });
        `,
        }}
      />
    </Layout>
  )
}

export default ContactPage
